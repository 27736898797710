

.club__mentorPage{
    background: radial-gradient(#1a1a38,#000000);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2em

}

.club__mentorPage-content{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:2em;
    margin-top: 1em;
}

.club__mentorPage-description{
    width: 25vw;
    /* background: white; */
    text-align: left;
}

@media screen and (max-width: 768px) {
    .club__mentorPage-content{
        flex-direction: column;
    }

    .club__mentorPage-description{
        width: 70vw;
    }
}