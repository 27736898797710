
.club__landingpage{
    background: radial-gradient(#1a1a38,#000000);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.club__landingpage-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1em;

    width: 50vw;
    /* background: red; */
}

.club__landingpage-content p{
    text-align: center;
}

.club__landing-content-btn{
    background: rgb(0, 0, 0);
    width: 60vw;
    min-height: 30vh;
    margin-top: 2em;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5px;
}

.club__landing-content-btn p.headline {
    text-align: center;
    font-size: 25px;
    color: white;
}

.club__landing-content-btn p.descriptions{
    font-size: 15px;
    color: white;
}

.button-wrapper{
    width: 400px;
    max-width: 100%;
    position: relative;
    margin-top: 1em;
}

.button-wrapper input{
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid white;
    border-radius: 50px;
    padding: 1rem 2rem;
    width: 100%;
}

.button-wrapper button{
    background: white;
    border-radius: 50px;
    aspect-ratio: 1;
    height: 80%;
    
    position: absolute;
    top: 10%;
    right: 5px;
}

.button-waitlist{
    padding: 1em 3em;
    border-radius: 50px;
    color: black;
    font-family: var(--font-alt);
    font-size: 20px;
    font-weight: 500;
    margin-top: 15px;
}



input{
    color: white;
    font-size: 1.1rem;
    font-weight: 500;

}

input:focus{
    outline: 2px solid black;
}

input::placeholder{
    color: rgba(255, 255, 255, 0.6);
}


button{
    border: none;
    cursor: pointer;
}

button:hover{
    opacity: 0.8;
}