.section__padding {
  padding: 4rem 6rem;
}

.box__container{
  min-height: 100vh;
  width: 100%;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.club__bg {
  /* background: url('./assets/bg.png'); */
  background-color: var(--color-black);
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.club__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.club__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.club__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.club__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.club__wrapper_img img {
  width: 80%;
}


/* do this one again */
/* .custom__button {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 20px;
  padding: 1.0rem 2.5rem;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
} */

/* .custom__button:hover{
  scale: 1.1;
} */

.p__inter {
  font-family: var(--font-base);
  color: white;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__fira-headline{
  font-family: var(--font-alt),sans-serif;
  color: white;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  /* line-height: 28px; */
  font-size: 70px;
}

.p__fira-headline span{
  color: #ffb703;
}

.p__fira-headline-yellow{
  font-family: var(--font-alt),sans-serif;
  color: #ffb703;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  /* line-height: 28px; */
  font-size: 70px;
}

.p__fira {
  font-family: var(--font-alt),sans-serif;
  color: white;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

.p__fira span{
  color:#ffb703;
}

.headtext__inter {
  font-family: var(--font-base);
  color: white;
  font-size: 45px;
  line-height: 80px;
  /* line-height: 50px; */
  letter-spacing: 0.04em;
  text-transform: capitalize;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.headtext__inter span{
  color: #ffb703;
}

.icon__img {
  width: 95px;
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__inter {
    font-size: 37px;
    line-height: 67px;
  }

  .p__fira {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__inter {
    font-size: 150px;
    line-height: 100px;
  }

  .icon__img {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 1150px) {
  
  .headtext__inter {
    line-height: 80px;
  }

  .club__wrapper {
    flex-direction: column;
  }

  .club__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .club__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .club__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {

  
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .p__inter {
    font-size: 21px;
  }

  .p__fira {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__fira {
    font-size: 12px;
  }

  .p__inter {
    font-size: 19px;
  }

  .headtext__inter{
    font-size: 20px;
    line-height: 30px;
  }

  .p__fira-headline{
    font-size: 50px;
    text-align: center;
  }

  .icon__img {
    width: 70px;
    height: 70px;
  }
}